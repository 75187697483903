
import { Component, Vue } from "vue-property-decorator";
import { BImg } from "bootstrap-vue";
@Component({
  name: "Logo",
  components: {
    BImg,
  },
})
export default class Logo extends Vue {
  get uriLogo(): string | null {
    const domain = localStorage.getItem("domain");

    if (domain) {
      if (domain != "Vona") return require(`@/assets/images/ico/${domain}.png`);
      else return null;
    }

    return domain;
  }
}
